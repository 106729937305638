















































































































import { computed, defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  setup(_, { root }) {
    const state = reactive({
      reveal: false
    })

    const filesUrl = computed(() => `${root.$store.state.api.baseURL}/static/location/`)

    return { state, filesUrl }
  }
})
